<template>
	<div data-route>
		<page-header
			v-if="getEnrolment"
			:heading="enrolment.title"
		/>
		<div data-element="main">
			<list-view
				v-if="getEnrolment"
				:list="getList"
			/>
		</div>
	</div>
</template>

<script>

	import PageHeader    from '@/components/ui/PageHeader';
	import ListView      from '@/components/ui/views/listView/ListView';
	import routeParams   from '@/mixins/routeParams';
	import api           from '@/services/api';
	import dayjs         from 'dayjs';
	import relativeTime  from 'dayjs/plugin/relativeTime';

	dayjs.extend(relativeTime);

	export default {
		metaInfo: {
			title: 'Sales overview'
		},
		components: {
			PageHeader,
			ListView
		},
		mixins: [routeParams],
		data: () => ({
			enrolment: undefined
		}),
		computed: {
			getEnrolmentId () {
				return this.$route.params.enrolmentId;
			},
			getTeacherId () {
				return this.$route.params.userId;
			},
			getEnrolment () {
				if (!this.enrolment ||
						!this.enrolment.enrolmentId) {
					return false;
				}
				return this.enrolment;
			},
			getBreadcrumbRoute () {
				if (!this.getEnrolmentId) {
					return false;
				}
				return `/admin/users/${this.getTeacherId}/sales/`;
			},
			getStudentNameString (enrolment) {
				if (!this.getEnrolment) {
					return false;
				}
				return `${this.getEnrolment.firstName} ${this.getEnrolment.lastName}`;
			},
			getJoinDateString () {
				if (!this.enrolment.joinDate) {
					return false;
				}
				return dayjs().to(this.enrolment.joinDate);
			},
			getList () {
				if (!this.getEnrolment) {
					return false;
				}
				return [
					{
						text: `Student: ${this.getStudentNameString}`
					},
					{
						text: `Date: ${this.getJoinDateString}`
					},
					{
						text: `Cost: ${this.getEnrolment.cost}`
					}
				].filter(Boolean);
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/users',
						text: 'Users'
					},
					{
						path: `/admin/users/${this.getUserId}`,
						text: this.getStudentNameString
					},
					{
						path: `/admin/users/${this.getUserId}/sales`,
						text: 'Sales'
					},
					{
						path: `/admin/users/${this.getUserId}/sales/${this.getEnrolmentId}`,
						text: this.enrolment?.title
					}
				].filter(Boolean);
			}
		},
		created: function () {
			this.setEnrolment();
		},
		methods: {
			async setEnrolment () {
				const enrolment = await api.admin.getEnrolmentById({
					enrolmentId: this.getEnrolmentId
				});
				if (!enrolment) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.enrolment = enrolment;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};
</script>
